import { render, staticRenderFns } from "./RosettesQuizPopup.vue?vue&type=template&id=a3e4efdc&scoped=true&"
import script from "./RosettesQuizPopup.vue?vue&type=script&lang=js&"
export * from "./RosettesQuizPopup.vue?vue&type=script&lang=js&"
import style0 from "./RosettesQuizPopup.vue?vue&type=style&index=0&id=a3e4efdc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3e4efdc",
  null
  
)

export default component.exports